import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import colors from './theme';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { AsYouType} from 'libphonenumber-js';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import { graphql, useStaticQuery,Link,navigate } from 'gatsby';

const FormContainer = styled.div`
  width: 600px;
  box-sizing: border-box;
  padding: 1em 1em 1em 1em;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  opacity: 1;
  height: 750px;
  z-index: 250;
  box-shadow: 1px 2px 3px ${colors.gray};
  @media (max-width: 700px){
    width: 100%;
}`

  const ContactFormMain = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;`

  const ContactFormNavigation = styled.div`
  height: 75px;
  display: flex;
  width: 100%;
  align-items: center;
  font-family: rubik;
  `

  const ContactFormTab = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  `

  const ContactFormRowFull = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: rubik, san-serif;
  font-size: 20px;
  `

  const ContactFormTitle = styled.h1`
  font-size: 25px;
  text-transform: uppercase;
  font-family: rubik, san-serif;
  font-weight: 700;`

  const ContactFormRowHalf = styled.div`
  width: 50%;
  display: flex;
  margin: 4px;
  flex-direction: row;
  font-family: rubik, san-serif;
  font-size: 20px;
  `

  const StyledInput = styled.input`
  box-sizing:border-box;
  padding: .5em;
  height: 50px;
  width: 100%;
  display: flex;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  font-size: 20px;
  font-family: rubik, san-serif;
  outline: none;
`

  const Label = styled.label`
  font-size: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  font-weight: 200;
  text-transform: uppercase;
  font-family: rubik, san-serif`

  const ContactTypeButton = styled.button`
  background: transparent;
  border-radius: 5px;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${colors.blue};
  color: black;
  padding: 0.5em 1em;
  outline: none;

  ${props =>
    props.primary &&
    css`
      background: ${colors.blue};
      color: black;
    `};
`

  const SubmitButton = styled.button`
  box-sizing:border-box;
  padding: 1em;
  width: 97%;
  height: 80px;
  background-color: ${colors.blue};
  color: #fff,
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;`

  const ExitButton = styled.a`
cursor: pointer;
font-size: 20px;
font-color: ${colors.gray};
position: absolute;
font-family: rubik;
top: 60px;
right: 20px;
`

const ContactPreferenceDetails = styled.input`
display: none;

`

const TourTypeDetails = styled.input`
display: none; 
`

const ThankYou = styled.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-family: rubik;
`

const Error = styled.div`
font-family: rubik;
font-size: 12px;
margin: 10px 0px 10px 0px;
color: red;
`

const PreQualDiv = styled.div`
height: 40px;
display: flex;
justify-content: center;
align-items: center;


`





const ContactForm = ({unit, setIsOpen, modal, allUnits, propertyName, northgatelander}) => {
    console.log("inside contact form component");
    let selected = allUnits ? allUnits.filter(item => {
        return item.node.id === unit
    }) : [];

    let selectedUnit = (selected && selected.length > 0)? `${propertyName} - ` + selected[0].node.unitNumber : propertyName;

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = values => {
        console.log(values);
  };

  const [startDate, setStartDate] = useState(new Date());

    const handleChange = date => {
        setStartDate(date);
    }

  



  //state mgmt

  const [contact, setContact] = useState('call');

  const onContactClick = (e) => {
    e.preventDefault();
    e.target.value==='call' ? setContact('call') : setContact('text');
  }

  const [tourType, setTourType] = useState('In-Person');

  const onTourClick = (e) => {
      e.preventDefault();
      e.target.value === 'In-Person' ? setTourType('In-Person') : setTourType('Virtual')
  }
  
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if( name === '' || (phone === '' && email === '')){
        setError(true)
        
    }
    else{
        setError(false);
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/3c2724d1-1321-4122-b306-f972c456ccd5",
            data: new FormData(form)
    })
        .then(r => {
            handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
        });
        
    }

    
  };

  return (
      <FormContainer>
           
        {modal && <ExitButton onClick = {() => setIsOpen(false)}>X</ExitButton>}
        {serverState.status ? (!northgatelander ? <ThankYou>Thank you! We'll be in touch soon.</ThankYou> : navigate("/thank-you") ) : 
        <ContactFormMain onSubmit={handleOnSubmit}>
           
           
            <ContactFormRowFull>
                <ContactFormTitle>
                    Get in Touch
                </ContactFormTitle>
            </ContactFormRowFull>
            {/* <ContactFormNavigation>
                <ContactFormTab>Request Info</ContactFormTab>
                <ContactFormTab>Schedule A Call</ContactFormTab>
                <ContactFormTab>Get Pre-Approved</ContactFormTab>
            </ContactFormNavigation> */}
            <ContactFormRowFull>
                <Label>
                    Name
                <StyledInput color={(name === '' && error) ? 'red' : colors.blue} key="name" value={name} onChange = {(e) => setName(e.target.value)} placeholder="Name" name="name" />
                </Label>
                
            </ContactFormRowFull>
            <ContactFormRowFull>
                <Label>
                    Unit of Interest
                <StyledInput disabled placeholder="Unit" name="unit" value={modal ?  selectedUnit : "The Linden - All"}/>
                </Label>
                
            </ContactFormRowFull>
            <ContactFormRowFull>
                <Label>
                    Phone Number
                <StyledInput color={(phone === '' && error) ? 'red' : colors.blue} placeholder="(555) 867-5309" name="phone" value={phone} onChange={(e)=>setPhone(new AsYouType('US').input(e.target.value))}/>
                </Label>
                
            </ContactFormRowFull>
            <ContactFormRowFull>
                <Label>
                    Email
                <StyledInput color={(email === '' && error) ? 'red' : colors.blue} placeholder="contact@you.com" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </Label>
                
            </ContactFormRowFull>
            
            <ContactFormRowFull>
                <Label>
                    Contact Preference
                        <ContactFormRowFull>
                        <ContactFormRowHalf>
                            <ContactTypeButton onClick = {(e)=>onContactClick(e)} primary={contact==='call'} value='call' >Call</ContactTypeButton>
                        </ContactFormRowHalf>
                        <ContactFormRowHalf>
                            <ContactTypeButton onClick = {(e)=>onContactClick(e)} value='text' primary={contact==='text'}>Text</ContactTypeButton>
                            
                        </ContactFormRowHalf>
                    </ContactFormRowFull>
                </Label>

            </ContactFormRowFull>
            <ContactFormRowFull>
                <Label>
                    Tour Preference
                        <ContactFormRowFull>
                        <ContactFormRowHalf>
                            <ContactTypeButton onClick = {(e)=>onTourClick(e)} primary={tourType==='In-Person'} value='In-Person' >In-Person</ContactTypeButton>
                        </ContactFormRowHalf>
                        <ContactFormRowHalf>
                            <ContactTypeButton onClick = {(e)=>onTourClick(e)} value='Virtual' primary={tourType==='Virtual'}>Virtual</ContactTypeButton>
                            
                        </ContactFormRowHalf>
                    </ContactFormRowFull>
                </Label>

            </ContactFormRowFull>
            <ContactFormRowFull>
                <Label>
                    Preferred Move-in Date
                    <Datepicker 
                    customInput={<StyledInput/>}
                    selected={startDate}
                    onChange={handleChange}
                    name="desired move-in date"
                    />
                </Label>
                
            </ContactFormRowFull>
            <ContactPreferenceDetails name="Contact Preference" value={contact}/>
            <TourTypeDetails name="Tour Preference" value={tourType}/>
            {error && <Error>Please complete all necessary fields.</Error>}
            <ContactFormRowFull>
               
                {serverState.submitting ? 
                <SubmitButton disabled = {true} type="submit"><Loader
                    type="Puff"
                    color={`${colors.gray}`}
                    height={40}
                    width={40}
                    timeout={3000}
                /></SubmitButton>
                :
                <SubmitButton type="submit" id="ReachOut">Reach out</SubmitButton>
                }
                
            </ContactFormRowFull>
            <ContactFormRowFull>
            {!northgatelander ? <PreQualDiv>Pre-qualify before you tour -&nbsp;<a href="/fastapp/"> Click Here!</a></PreQualDiv> : null }
            </ContactFormRowFull>
            
            
        </ContactFormMain>
        }
        
      </FormContainer>
        

  )

  //styles

  
    
}

export default ContactForm;